import questionnaireSummariesPath from 'consts/paths/api/questionnaires/getSummaries';
import useApi from 'hooks/useApi';
import { useQuery } from 'react-query';
import QuestionnaireType from '_types/api/questionnaires/QuestionnaireType';
import QuestionnaireSummary from '_types/api/questionnaires/QuestionnaireSummary';
import QuestionnaireKind from '_types/api/questionnaires/QuestionnaireKind';
import { questionnairesBaseQueryKey } from '../../consts/sharedQueryKeys';

type useQuestionnairesSummariesProps = {
    filters?: {
        type?: QuestionnaireType | null;
        kind?: QuestionnaireKind;
    };
};

const useQuestionnairesSummaries = ({ filters }: useQuestionnairesSummariesProps) => {
    const { type, kind } = filters || {};

    const api = useApi();
    const {
        data: questionnaires,
        error,
        isLoading,
    } = useQuery([questionnairesBaseQueryKey, 'summaries', { kind }], async () => {
        const result = await api.get<QuestionnaireSummary[]>(questionnaireSummariesPath, { params: { kind } });
        return result?.data;
    });

    return {
        questionnaires: type ? questionnaires?.filter((item) => item.type === type) : questionnaires,
        isLoading,
        error,
        type,
    };
};

export default useQuestionnairesSummaries;
