import * as yup from 'yup';
import getIsRequiredText from 'functions/validation/getIsRequiredText';
import QuestionnaireType from '_types/api/questionnaires/QuestionnaireType';
import Gender from '_types/Gender';

export const schema = yup.object().shape({
    code: yup.string().required(getIsRequiredText('Code')),
    name: yup.string().required(getIsRequiredText('Name')),
    type: yup.mixed<QuestionnaireType>().oneOf(Object.values(QuestionnaireType)).required(getIsRequiredText('Type')),
    phase: yup.number().required(getIsRequiredText('Phase')),
    gender: yup.string().required(getIsRequiredText('Gender')),
    contact: yup.string().email('Contact must be a valid email').required(getIsRequiredText('Email')),
    questionsStartPosition: yup
        .number()
        .typeError('First question must only contain digits')
        .min(1, 'First question must be greater than or equal to 1.')
        .max(1000, 'First question must be lower than or equal to 1000.')
        .required(getIsRequiredText('First question')),
    description: yup.string(),
    additionalInfo: yup.string(),
});

export const initialValues = {
    code: '',
    name: '',
    type: QuestionnaireType.MODULE,
    phase: 5,
    gender: Gender.NEUTRAL,
    contact: '',
    questionsStartPosition: 1,
    description: '',
    additionalInfo: '',
};
