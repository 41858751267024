import Field from 'components/_form/Field';
import { Form } from 'react-bootstrap';
import FormModal from 'components/_modal/FormModal';
import { initialValues as defaultInitialValues, schema } from './schema';
import QuestionnaireTypeSelect from 'components/_form/Select/QuestionnaireTypeSelect';
import FormGenderSelect from 'components/_form/Select/GenderSelect';
import styles from './officialQuestionnaireFormModal.module.scss';
import RichTextEditor from 'components/_form/RichTextEditor';
import FormPhaseSelect from 'components/_form/Select/PhaseSelect';
import QuestionnaireKind from '_types/api/questionnaires/QuestionnaireKind';
import QuestionnaireType from '_types/api/questionnaires/QuestionnaireType';
import GenderType from '_types/Gender';
import Phase from '_types/Phase';

export type OfficialQuestionnaireFormModalProps = {
    toggle: () => void;
    isShowing: boolean;
    onSubmit: (values: OfficialQuestionnaireValues) => Promise<unknown>;
    title: string;
    submitButtonText?: string;
    initialValues?: OfficialQuestionnaireValues;
    isResetAfterSubmit?: boolean;
};

export type OfficialQuestionnaireValues = {
    code: string;
    name: string;
    type: QuestionnaireType;
    phase: Phase;
    gender: GenderType;
    contact: string;
    questionsStartPosition: number;
    description: string;
    additionalInfo: string;
};

const OfficialQuestionnaireFormModal = ({
    isShowing,
    toggle,
    title,
    submitButtonText = 'Save',
    onSubmit,
    initialValues,
    isResetAfterSubmit = false,
}: OfficialQuestionnaireFormModalProps) => {
    return (
        <FormModal
            title={title}
            toggle={toggle}
            isShowing={isShowing}
            validationSchema={schema}
            submitButtonText={submitButtonText}
            initialValues={initialValues || defaultInitialValues}
            onSubmit={onSubmit}
            onComplete={() => toggle()}
            customSize
            isResetAfterSubmit={isResetAfterSubmit}
        >
            {({ register, control, formState: { errors }, setValue }) => (
                <>
                    <Field label="code" id="code" feedback={errors.code}>
                        <Form.Control isInvalid={!!errors.code} placeholder="e. g. BR" {...register('code')} />
                    </Field>
                    <Field label="name" id="name" feedback={errors.name}>
                        <Form.Control
                            isInvalid={!!errors.name}
                            placeholder="e. g. Breast Module"
                            {...register('name')}
                        />
                    </Field>
                    <Field label="type" id="type" feedback={errors.type}>
                        <QuestionnaireTypeSelect
                            control={control}
                            name="type"
                            label=""
                            kind={QuestionnaireKind.OFFICIAL}
                        />
                    </Field>
                    <Field
                        label="First question"
                        id="questionsStartPosition"
                        feedback={errors.questionsStartPosition}
                        key="questionsStartPosition"
                    >
                        <Form.Control
                            isInvalid={!!errors.questionsStartPosition}
                            placeholder="1"
                            defaultValue={1}
                            type="number"
                            min={1}
                            {...register('questionsStartPosition')}
                        />
                        <p className={styles.helpInfoText}>The numbering of questions starts from this number.</p>
                    </Field>
                    <Field label="testing" id="phase" feedback={errors.phase}>
                        <FormPhaseSelect control={control} name="phase" label="" />
                    </Field>
                    <Field label="gender" id="gender" feedback={errors.gender}>
                        <FormGenderSelect control={control} name="gender" label="" />
                    </Field>
                    <Field label="contact" id="contact" feedback={errors.contact}>
                        <Form.Control
                            type="email"
                            {...register('contact')}
                            placeholder="e.g john.doe@eortc.org"
                            isInvalid={!!errors.contact}
                        />
                    </Field>
                    <Field label="description" id="description" feedback={errors.description} key="description">
                        <RichTextEditor
                            name="description"
                            setValue={setValue}
                            control={control}
                            placeholder="any other information worth mentioning..."
                        />
                    </Field>
                    <Field
                        label="additional Info"
                        id="additionalInfo"
                        feedback={errors.additionalInfo}
                        key="additionalInfo"
                    >
                        <RichTextEditor
                            name="additionalInfo"
                            setValue={setValue}
                            control={control}
                            placeholder="any other information worth mentioning..."
                        />
                    </Field>
                </>
            )}
        </FormModal>
    );
};

export default OfficialQuestionnaireFormModal;
