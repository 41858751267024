import useAlerts from 'hooks/useAlerts';
import OfficialQuestionnaireFormModal, { OfficialQuestionnaireValues } from '..';
import useCreateQuestionnaire from 'hooks/useCreateQuestionnaire';
import { stripHtmlFromStringIfEmpty } from 'functions/string/stripHtmlFromString';
export type CreateOfficialQuestionnaireFormModalProps = {
    toggle: () => void;
    isShowing: boolean;
};

const CreateOfficialQuestionnaireFormModal = ({ isShowing, toggle }: CreateOfficialQuestionnaireFormModalProps) => {
    const { addSuccessAlert } = useAlerts();

    const { createQuestionnaire } = useCreateQuestionnaire();

    const onSubmit = async (formData: OfficialQuestionnaireValues) => {
        const { additionalInfo, description } = formData;
        const fixedAdditionalInfo = stripHtmlFromStringIfEmpty(additionalInfo);
        const fixedDescription = stripHtmlFromStringIfEmpty(description);
        await createQuestionnaire({
            ...formData,
            isCustom: false,
            description: fixedDescription,
            additionalInfo: fixedAdditionalInfo,
        });
        addSuccessAlert('Questionnaire created!');
    };

    return (
        <OfficialQuestionnaireFormModal
            isShowing={isShowing}
            toggle={toggle}
            title="Add Questionnaire"
            onSubmit={onSubmit}
            isResetAfterSubmit={true}
        />
    );
};

export default CreateOfficialQuestionnaireFormModal;
