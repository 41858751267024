import MainPageWrapper from 'components/PageWrapper/HomePageWrapper';
import OfficialQuestionnairesPage from 'components/OfficialQuestionnairesPage';

const Home = () => {
    return (
        <MainPageWrapper>
            <OfficialQuestionnairesPage />
        </MainPageWrapper>
    );
};

export default Home;
